<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="noRecord">您暂无兑换记录</div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: 100vh;
        position: relative;
        .noRecord{
            margin: 0 auto;
            font-size: 14px;
            color: #666;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
        }
    }
</style>